import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';
import { StylesProps } from '@/utils/stylesProps';

type Props = StylesProps & ThemeProps;

export const Container = styled.header`
  height: 5rem;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.75rem;
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0 1.875rem;
  }
  @media (max-width: 768px) {
    display: none;
  }
  li {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${(props: ThemeProps) => props.theme.blackColor};
    border-radius: 0.25rem;
    &:last-child {
      margin-left: 3rem;
      width: 9rem;
      height: 3rem;
      @media (max-width: 768px) {
        width: 7.125rem;
        height: 2.375rem;
      }
    }
  }
`;

export const NavLinks = styled.div`
  display: flex;
  margin-left: 2rem;
  font-weight: 500;
  @media (min-width: 769px) {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 768px) {
    margin: 4rem 0 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    & a {
      display: inline-block;
      margin: 1.5rem 0;
    }
  }
`;

export const SocialLinksList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
  }
  li {
    text-align: center;
    & a {
      padding: 1rem;
      & svg {
        height: 1.125rem;
      }
    }
    @media (min-width: 769px) {
      &:hover {
        transform: scale(1.1);
      }
    }
    @media (min-width: 769px) and (max-width: 992px) {
      &:last-child {
        margin-left: 1rem;
      }
    }
    @media (max-width: 768px) {
      & a svg {
        height: 1.625rem;
      }
      &:last-child {
        width: 100%;
        height: 3rem;
      }
    }
  }
`;

export const MobileNavigation = styled.nav`
  z-index: 3;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  width: 100%;
  min-width: 18.75rem;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  & > * {
    z-index: 4;
  }
`;

export const BurgerMenuContainer = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BurgerMenu = styled.div`
  width: 0.8125rem;
  height: 0.125rem;
  border-radius: 0.375rem;
  background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
  &:after,
  :before {
    content: '';
    position: absolute;
    width: 1.3125rem;
    height: 0.125rem;
    background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
    border-radius: 0.375rem;
  }
  &:before {
    transform: translateY(-0.5rem);
  }
  &:after {
    transform: translateY(0.5rem);
  }
`;

export const BurgerMenuCross = styled.div`
  width: 1.3125rem;
  height: 0.125rem;
  border-radius: 0.375rem;
  background-color: transparent;
  &:after,
  :before {
    content: '';
    position: absolute;
    width: 1.3125rem;
    height: 0.125rem;
    background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
    border-radius: 0.375rem;
  }
  &:before {
    transform: translateY(-0.5rem);
    transform: rotate(45deg);
  }
  &:after {
    transform: translateY(0.5rem);
    transform: rotate(-45deg);
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  top: 0;
  right: 0;
  width: ${(props: Props) => (props.isOpen ? `100%` : `0`)};
  transition: width 0.2s ease-out;
  box-sizing: border-box;
  height: 100vh;
  z-index: 3;
  overflow: hidden;
  padding: ${(props: Props) => (props.isOpen ? `5rem 1rem 6rem` : `0`)};
  & button {
    height: 3rem;
  }
  & ul {
    display: ${(props: Props) => (props.isOpen ? `flex` : `none`)};
  }
`;

export const LogoLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;
