import Link from 'next/link';
import dynamic from 'next/dynamic';
import {
  Container,
  Navigation,
  MobileNavigation,
  NavContainer,
  BurgerMenu,
  BurgerMenuCross,
  BurgerMenuContainer,
  LogoLinkWrapper,
  LinksWrapper,
} from './Header.styles';
import { useState, useEffect } from 'react';
import { TCategory } from '@/utils/categories';

const HeaderNavLinks = dynamic(() => import(`./HeaderNavLinks`));
const SocialLinks = dynamic(() => import(`./SocialLinks`));

interface Props {
  currentCategory?: TCategory | undefined;
}

const Header = ({ currentCategory }: Props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflowY = open ? `hidden` : `scroll`;
  }, [open]);
  const close = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Navigation>
        <Link href="/" passHref>
          <LogoLinkWrapper>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src="/logo.png"
              alt="JobsHash Logo"
              width="152px"
              height="32px"
            />
          </LogoLinkWrapper>
        </Link>
        <HeaderNavLinks />
        <SocialLinks currentCategory={currentCategory} />
      </Navigation>
      <MobileNavigation>
        <NavContainer>
          <Link href="/" passHref>
            <LogoLinkWrapper onClick={close}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="/logo.png"
                alt="JobsHash Logo"
                width="152px"
                height="32px"
                loading="lazy"
              />
            </LogoLinkWrapper>
          </Link>
          {open ? (
            <BurgerMenuContainer onClick={close}>
              <BurgerMenuCross />
            </BurgerMenuContainer>
          ) : (
            <BurgerMenuContainer onClick={() => setOpen(true)}>
              <BurgerMenu />
            </BurgerMenuContainer>
          )}
          <LinksWrapper isOpen={open}>
            <HeaderNavLinks onClick={close} />
            <SocialLinks currentCategory={currentCategory} onClick={close} />
          </LinksWrapper>
        </NavContainer>
      </MobileNavigation>
    </Container>
  );
};

export default Header;
